import 'styles/forms.css';
import 'styles/nprogress.min.css';

import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import NProgress from "nprogress";
import { useRouter } from "next/router";
import { useEffect } from 'react';
import { GoogleAnalytics } from "nextjs-google-analytics";
import { isEmpty } from 'utils/commonUtils';
import Script from 'next/script';
import Head from 'next/head';

const RenderScripts = dynamic(() => import('components/RenderScripts/RenderScripts'));
const Layout = dynamic(() => import('components/Layout'));

const App = ({ Component, pageProps, ...appProps }: AppProps) => {
    const router = useRouter();

    const globalScripts = pageProps?.settings?.globalScripts?.scriptList;
    const activeScripts = Boolean(globalScripts?.enableScripts ?? false) && process.env.NODE_ENV !== 'test';
    const validPages = !['login', 'admin'].includes(String(pageProps.page));

    const facebookPixelId = globalScripts?.customScripts?.facebookPixel?.id || '';
    const allowPixel = Boolean(activeScripts && validPages && !isEmpty(facebookPixelId));
    const allowIndexAndCrawled = (appProps.router.pathname).indexOf('setculture') === -1;

    useEffect(() => {
        // pixel
        if (allowPixel) {
            window.fbq('track', 'PageView');
        }

        const onRouteChangeStart = () => { NProgress.start(); };
        const onRouteChangeComplete = () => {
            NProgress.done();
            const { pathname } = router;
            const formattedPathname = pathname.toLowerCase();
            if (pathname !== formattedPathname) {
                router.push(formattedPathname);
            }
            if (allowPixel) {
                window.fbq('track', 'PageView');
            }
        };

        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);
        router.events.on('routeChangeError', onRouteChangeComplete);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
            router.events.off('routeChangeError', onRouteChangeComplete);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events]);

    if (pageProps.page === 'sitemapxml') return null;

    if (['/clearcache', '/404'].includes(appProps.router.pathname)) {
        return <Component {...pageProps} />;
    }

    return (
        <>            
            <Head>
                {allowIndexAndCrawled && (
                    <meta name="robots" content="INDEX, FOLLOW"></meta>
                )}
                {!allowIndexAndCrawled && (
                    <>
                        <meta name="robots" content="NOINDEX, NOFOLLOW"></meta>
                        <meta name="googlebot" content="NOINDEX, NOFOLLOW"></meta>
                    </>
                )}
            </Head>            
            {allowPixel &&
                <Script
                    id="fb-pixel"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', ${facebookPixelId});
                    `,
                    }}
                />
            }
            <Layout
                page={pageProps.page}
                settings={pageProps.settings}
                titlePage={pageProps.titlePage}
                friendlyUrl={pageProps.friendlyUrl}
                keywords={pageProps.keywords}
                metaDescription={pageProps.metaDescription}
            >
                {activeScripts && validPages && !isEmpty(globalScripts?.customScripts?.googleAnalytics?.id) &&
                    <GoogleAnalytics strategy='lazyOnload'
                        trackPageViews={{ ignoreHashChange: true }}
                        gaMeasurementId={globalScripts?.customScripts?.googleAnalytics?.id}
                    />
                }
                {activeScripts && validPages && globalScripts?.scriptsListOnHead?.length > 0 &&
                    <RenderScripts scriptsList={globalScripts?.scriptsListOnHead} scriptPosition="scriptOnHead" />
                }

                {activeScripts && validPages && globalScripts?.scriptsListOnBody?.length > 0 &&
                    <RenderScripts scriptsList={globalScripts?.scriptsListOnBody} scriptPosition="scriptOnBody" />
                }

                <Component {...pageProps} />
            </Layout>
            {activeScripts && validPages && globalScripts?.scriptsListOnCloseBody?.length > 0 &&
                <RenderScripts scriptsList={globalScripts?.scriptsListOnCloseBody} scriptPosition="scriptOnCloseBody" />
            }
        </>
    );
};

export default App;