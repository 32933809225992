const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

function numberWithCommas(val: number, round = 0) {
    return Number(val)
        .toFixed(round)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommasAndSymbol(val: number, round = 0) {
    return '$'+ numberWithCommas(val, round);
}

function addParamToVehicleUrl(paramName: string, paramValue: string, url = "") {
    if (paramValue === null || paramValue === "")
        return removeParamFromUrl(paramName);
    if (url === "") {
        const query = window.location.href;
        const onlyParameters = query.indexOf("?") !== -1 ? query.split("?")[1] : "";
        if (onlyParameters && onlyParameters !== "")
            url = "vehicles?" + onlyParameters;
        else url = query;
    }

    // url = url.replace("#", "");
    if (paramValue === null) paramValue = "";

    const pattern = new RegExp("\\b(" + paramName + "=).*?(&|$)");
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, "$1" + paramValue + "$2");
    }
    url = url + (url.indexOf("?") > 0 ? "&" : "?") + paramName + "=" + paramValue;

    return url;
}

function removeParamFromUrl(key: string, url = "") {
    if (url === "") {
        url = document.location.search;
    }

    let rtn = url.split("?")[0];
    const queryString = url.indexOf("?") !== -1 ? url.split("?")[1] : "";

    if (queryString !== "") {
        const params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            const param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }

        if (params_arr.length > 0) rtn = rtn + "?" + params_arr.join("&");
    }

    return rtn;
}

const groupBy = (arrayData: Array<any>, groupByName: string) => {
    if (!arrayData) return arrayData;

    return arrayData.reduce((group, product) => {
        const category = product[groupByName];
        group[category] = group[category] ?? [];
        group[category].push(product);
        return group;
    }, {});
};

const groupByCount = (arrayData: Array<any>, groupByName: string) => {
    if (!arrayData) return arrayData;
    const objectGroup = groupBy(arrayData, groupByName);
    const newArrayWithCount: { [x: string]: any; data: any; count: any }[] = [];

    for (const prop in objectGroup) {
        if (Object.prototype.hasOwnProperty.call(objectGroup, prop)) {
            newArrayWithCount.push({
                [groupByName]: [prop].toString(),
                data: objectGroup[prop],
                count: prop.length,
            });
        }
    }

    return newArrayWithCount;
};

const removeEmptyProperties = (obj: any) => {
    return Object.keys(obj)
        .filter(function (k) {
            return obj[k] != null;
        })
        .reduce(function (acc: any, k) {
            acc[k] = typeof obj[k] === "object" ? removeEmptyProperties(obj[k]) : obj[k];
            return acc;
        }, {});
};

const onlyNumbers = (val: string) => {
    if(!val) return null;
    return val.replace(/\D/g, '') ?? null;
};

function phoneNumberWithDash(v: string) {
    let r = v.replace(/\D/g,"");
    r = r.replace(/^0/,"");

    if (r.length > 10) {
        r = r.replace(/^(\d{3})(\d{3})(\d{4}).*/,"$1-$2-$3");
    }
    else if (r.length > 6) {
        r = r.replace(/^(\d{3})(\d{3})(\d{0,4}).*/,"$1-$2-$3");
    }
    else if (r.length > 3) {
        r = r.replace(/^(\d{3})(\d{0,3})/,"$1-$2");
    }
    else {
        r = r.replace(/^(\d*)/, "$1");
    }
    return r;
}

const isEmpty = (value: any) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export {
    getKeyValue,
    numberWithCommas,
    addParamToVehicleUrl,
    removeParamFromUrl,
    groupBy,
    groupByCount,
    numberWithCommasAndSymbol,
    removeEmptyProperties,
    onlyNumbers,
    phoneNumberWithDash,
    isEmpty,
};
